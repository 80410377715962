import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  Suspense,
} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import "swiper/css/effect-creative";

import {
  NavLink,
  NavLinks,
  NavToggle,
  LogoLink,
  DesktopNavLinks,
  HeaderNavigation,
} from "./Components/NavigationComponent.jsx";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Virtual,
} from "swiper/core";
import { EffectCreative } from "swiper";
import { RouterLink } from "@/components/utilities/RouterLink.jsx";
import { LanguageContext } from "@/store/langContext.jsx";
import { ResponsiveVideoEmbed } from "@/helpers/ResponsiveVideoEmbed.jsx";
import { setVideoLinksWithoutCookie } from "@/helpers/helperFunctions";
import { InternalLink, ExternalLink } from "../../utilities/RenderLinks.jsx";
import { fetchHeaderData } from "../../../helpers/queries.js";
import { useQuery } from "react-query";

const NavigationContainer = tw.div`w-full fixed top-0 left-0 z-30 bg-primary-700/[.90] backdrop-blur-sm`;
const StyledHeader = styled(HeaderNavigation)`
  ${tw`max-w-screen-xl px-6 py-4 sm:px-16 `}
  ${DesktopNavLinks} ${NavLink},
    ${LogoLink} {
    ${tw`text-gray-200 hover:border-gray-300 hover:text-gray-100`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  min-height: 400px;
  @media (max-width: 768px) {
    min-height: 200px;
  }
  ${tw`relative p-8 -mt-8 bg-center bg-cover`}
  background-image: url("/img/atworkbanner.webp");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500/[0.35]`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = styled.div`
  ${tw`flex flex-col-reverse items-center justify-between pt-24 pb-10 mt-8 sm:pb-20 lg:pb-16 sm:px-12 lg:flex-row`}// min-height:300px
`;
const LeftColumn = tw.div`w-full lg:w-1/2 flex flex-col items-center lg:block`;

const RightColumn = tw.div`w-full sm:w-4/6 lg:w-1/2 mt-8 lg:mt-0 lg:pl-8 relative`;
const HeaderImgDiv = tw.div`flex items-center justify-end h-full p-4 `;
const HeaderImg = tw.img`h-full object-cover w-full rounded-md`;

const Heading = styled.h1`
  ${tw`text-3xl font-semibold leading-none text-center text-gray-100 lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative px-4 py-2 -mx-4 text-primary-500`}
  &::before {
    content: "";
    ${tw`absolute inset-0 transform -skew-x-12 bg-gray-100 -z-10`}
  }
`;

const Logo = (
  <Link to="/" className="logoContainer" aria-label="Home">
    <img
      src={"/img/delegateLogo.webp"}
      alt="logo"
      loading="lazy"
      height="60"
      width="60"
    />
    Delegate365
  </Link>
);

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-lg`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`};

  iframe {
    ${tw`bg-black rounded shadow-xl`}
  }
`;
const primaryStyle =
  "text-xl mt-10 bg-white border-white text-primary-500 font-semibold rounded  hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline";
const PrimaryExternalLink = tw(ExternalLink)`${primaryStyle}`;
const PrimaryInternalLink = tw(InternalLink)`${primaryStyle}`;

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

export const MainHeader = () => {
  const { isError, isLoading, data, error } = useQuery(
    "HeaderData",
    fetchHeaderData
  );
  const { language, changeLanguage, getTranslatedData } =
    useContext(LanguageContext);

  if (isLoading) return null;
  const prepareLinks = (linksgroup) => {
    return linksgroup.map((links, i) => (
      <NavLinks key={i}>
        {links.map((link) =>
          link.button && link.button === "changeLanguage" ? (
            <button
              onClick={() => changeLanguage(link.value)}
              key={link.value}
              aria-label={getTranslatedData(link.title)}
              className={`link button ${
                language === link.value ? " active" : ""
              }`}
            >
              {getTranslatedData(link.title)}
            </button>
          ) : (
            <RouterLink
              url={link.url}
              key={link.url}
              title={link.title}
              aria-label={getTranslatedData(link.title)}
              lang={language}
              primary={link.primary}
            />
          )
        )}
      </NavLinks>
    ));
  };

  return (
    <Suspense>
      <Container>
        <OpacityOverlay />
        <HeroContainer className={"hero"}>
          <NavigationContainer>
            <StyledHeader links={prepareLinks(data.navlinks)} logoLink={Logo} />
          </NavigationContainer>
          <Swiper
            autoplay={{ delay: 10000 }}
            slidesPerView={1}
            navigation
            loop
            grabCursor
            effect={"creative"}
            lazy
            pagination={{ clickable: true }}
            creativeEffect={{
              prev: {
                shadow: true,
                origin: "left center",
                translate: ["-5%", 0, -200],
                rotate: [0, 100, 0],
                opacity: 0,
              },
              next: {
                origin: "right center",
                translate: ["5%", 0, -200],
                rotate: [0, -100, 0],
                opacity: 0,
              },
            }}
            modules={[EffectCreative, Pagination]}
          >
            {data.bannerElements.map((header, i) => (
              <SwiperSlide key={i}>
                <TwoColumn>
                  <LeftColumn>
                    <Notification>
                      {parse(getTranslatedData(header.subtitle))}
                    </Notification>
                    <Heading>
                      <span>{parse(getTranslatedData(header.title))}</span>
                    </Heading>
                    {header.button &&
                      (header.button.external ? (
                        <PrimaryExternalLink
                          href={header.button.url}
                          rel="noreferrer"
                          target="_blank"
                          aria-label={getTranslatedData(
                            header.button.buttonText
                          )}
                        >
                          {getTranslatedData(header.button.buttonText)}
                        </PrimaryExternalLink>
                      ) : (
                        <PrimaryInternalLink
                          to={header.button.url}
                          aria-label={getTranslatedData(
                            header.button.buttonText
                          )}
                        >
                          {getTranslatedData(header.button.buttonText)}
                        </PrimaryInternalLink>
                      ))}
                  </LeftColumn>
                  {header.content.type && (
                    <RightColumn>
                      {header.content.type === "image" && (
                        <HeaderImgDiv style={{ maxHeight: 400 }}>
                          <HeaderImg
                            style={{ maxHeight: 300 }}
                            width="300"
                            height="300"
                            src={header.content.url}
                            alt={header.title}
                            loading={i > 0 ? "lazy" : ""}
                          />
                        </HeaderImgDiv>
                      )}
                      {header.content.type === "video" && (
                        <StyledResponsiveVideoEmbed
                          url={setVideoLinksWithoutCookie(header.content.url)}
                          timeout={5000}
                        />
                      )}
                    </RightColumn>
                  )}
                </TwoColumn>
              </SwiperSlide>
            ))}
          </Swiper>
        </HeroContainer>
      </Container>
    </Suspense>
  );
};
