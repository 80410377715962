/* A simple iframe wrapper that makes 16:9 responsive embed */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useEffect, useState } from "react";

import { fas } from "fontawesome.macro";
import tw from "twin.macro";
import styled from "styled-components";
import { useMemo } from "react";

const LoadingOverlay = tw.div`h-full w-full flex items-center justify-center text-gray-200 absolute bottom-0 left-0`;

export const ResponsiveVideoEmbed = ({
  url,
  background = "black",
  className = "video",
  timeout = 100,
}) => {
  const [loading, setLoading] = useState(true);
  const iframe = useRef(null);
  const renderVideo = () => {
    setLoading(true);
    setTimeout(() => {
      iframe.current?.setAttribute("src", url);
      setLoading(false);
    }, timeout);
  };
  useMemo(() => {
    renderVideo();
  }, [url]);
  return (
    <div
      className={className}
      style={{
        position: "relative",
        background: background,
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      {loading && (
        <LoadingOverlay>
          <FontAwesomeIcon
            icon={fas`spinner`}
            className="fa-spin-pulse fa-5x"
          />
        </LoadingOverlay>
      )}
      <iframe
        width="560"
        height="315"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        ref={iframe}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};
