import React, { useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import { LanguageContext } from "@/store/langContext.jsx";

const linkClasses =
  "w-full sm:w-auto text-xl text-center px-6 py-2 mt-4 rounded font-semibold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline inline-block  bg-primary-500 text-white hocus:bg-transparent border-primary-500 hocus:text-primary-500 shadow-xl mr-0 sm:mr-2 hocus:translate-x-px hocus:-translate-y-px";

export const ExternalLink = tw.a`${linkClasses}`;
export const InternalLink = styled(Link)`
  ${tw`${linkClasses}`}
`;

export const RenderLinks = ({ links }) => {
  const { getTranslatedData } = useContext(LanguageContext);
  return (
    <div>
      {links.map((link, i) =>
        link.external ? (
          <ExternalLink
            href={link.url}
            target="_blank"
            rel="noreferrer"
            key={i}
            aria-label={getTranslatedData(link.linkText)}
          >
            {getTranslatedData(link.linkText)}
          </ExternalLink>
        ) : (
          <InternalLink
            to={link.url}
            key={i}
            aria-label={getTranslatedData(link.linkText)}
          >
            {getTranslatedData(link.linkText)}
          </InternalLink>
        )
      )}
    </div>
  );
};
