import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
export const LanguageContext = createContext({});

export const fetchLanguages = async () => {
  const response = await axios.get("/data/languages.json");
  const data = response.data;
  return data;
};

export const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");
  const { data, isSuccess } = useQuery(["languages"], fetchLanguages, {
    staleTime: 300000,
  });

  const changeLanguage = (language) => {
    if (!data.includes(language)) return;
    setLanguage(language);
    window.localStorage.setItem("language", language);
  };

  const getSavedLanguage = () => {
    const language = window.localStorage.getItem("language");
    if (data && !data.includes(language)) return setLanguage("en");
    if (language !== "en") setLanguage(language);
  };

  const getTranslatedData = (element) =>
    element[language] ? element[language] : "No Text Found";

  useEffect(() => {
    getSavedLanguage();
  }, [data]);
  return (
    <LanguageContext.Provider
      value={{ language, changeLanguage, getTranslatedData }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
