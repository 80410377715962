import React from "react";
import { NavLink } from "react-router-dom";
export const RouterLink = ({ url, primary, title, lang, styles }) => {
  return (
    <NavLink
      to={`${url}`}
      className={`link ${primary && "primary"}`}
      style={styles}
    >
      {lang === "de" ? title.de : title.en}
    </NavLink>
  );
};
